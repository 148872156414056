.galleryContainer {
  width: 100%;
  height: 455px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 2rem auto;
}
@media (max-width: 767px) {
  .galleryContainer {
    height: 320px;
    margin-bottom:10px;
  }
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  color: #FFF;
  font-size: 22px;
  background: #666793;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 32px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev i,
.next i {
  font-style: normal;
  margin-top: -5px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

.galleryContainer .refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  background: #e71019;
  border-radius: 10px;
  z-index: 10;
  padding: 3px 10px;
}
