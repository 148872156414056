.numN:first-of-type {
  margin-right: 14px;
  margin-left: 0;
}
.numN {
  margin-right: 14px;
  margin-left: 14px;
}
.numN:last-of-type {
  margin-right: 0;
  margin-left: 14px;
}

.numN:nth-last-of-type(3n)::before {
  display: block;
  content: ",";
  position: relative;
  transform: translate(-55px, 15px);
}

.trackingNumbers {
  letter-spacing: 0.6em;
  margin-top: 14px;
}

@media screen and (max-width: 1024px) {
  /* lg */
  .numN:first-of-type {
    margin-right: 10px;
    margin-left: 0;
  }
  .numN {
    margin-right: 10px;
    margin-left: 10px;
  }
  .numN:last-of-type {
    margin-right: 0;
    margin-left: 10px;
  }
  .numN:nth-last-of-type(3n)::before {
    display: block;
    content: ",";
    position: relative;
    transform: translate(-40px, 15px);
  }
}

@media screen and (max-width: 768px) {
  /* md */
  .numN:first-of-type {
    margin-right: 8px;
    margin-left: 0;
  }
  .numN {
    margin-right: 8px;
    margin-left: 8px;
  }
  .numN:last-of-type {
    margin-right: 0;
    margin-left: 8px;
  }
  .numN:nth-last-of-type(3n)::before {
    display: block;
    content: ",";
    position: relative;
    transform: translate(-30px, 5px);
  }
  .trackingNumbers {
    letter-spacing: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  /* sm */
  .numN:first-of-type {
    margin-right: 6px;
    margin-left: 0;
  }
  .numN {
    margin-right: 6px;
    margin-left: 6px;
  }
  .numN:last-of-type {
    margin-right: 0;
    margin-left: 6px;
  }
  .numN:nth-last-of-type(3n)::before {
    display: block;
    content: ",";
    position: relative;
    transform: translate(-25px, 5px);
  }
}
